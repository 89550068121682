
import {
    Component,
    Vue
} from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import {
    getComponent
} from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import schedule from '@/router/routes/schedule';

@Component({
    components: {
        WorkflowAutomationDialog: () => getComponent('tasqs/WorkflowAutomationDialog'),
    },
})
export default class PersonnelSchedulerNavbar extends Vue {

    isSavingFromPopup = false

    dataLoading = false
    addChartPopupShowing = false
    showContentPopup = false

    showAddChartSelectionPopup = false
    newRigChartDetailsShowing = false
    newWellTestChartDetailsShowing = false

    showEditScheduleDropdown = false

    modifySchedulePopup = false
    editScheduleInnerPopup = false

    deleteScheduleComponent: any;
    editScheduleNameInput = ''

    get schedules(): any[] {
        return scheduleModule.personnelSchedules
    }

    get selectedSchedule() {
        // @ts-ignore
        if (scheduleModule.activePersonnelSchedule != null) {
            // @ts-ignore
            return scheduleModule.activePersonnelSchedule.ID
        }
        return null
    }

    get tableEditDetails() {
        return this.showEditScheduleDropdown
    }

    async editTablePopupApplyButtonTapped() {
        this.isSavingFromPopup = true

        await scheduleModule.updateTableName({
            // @ts-ignore
            table_id: this.showEditScheduleDropdown.id,
            table_name: this.editScheduleNameInput
        })
        //   this.isSavingFromPopup = false
        window.location.reload();

    }


	undoChangesClicked() {
		this.$emit('call-child-reload-page');
		
		// this.reloadPage()
	}

	publishChangesClicked() {
		this.$emit('call-child-publish-page')
	}

	get didModifyComponentsOnChart() {
		for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
			if (scheduleModule.totalComponents[x].changed) {
				return true
			}
		}
		return false
	}




    editScheduleTitleSelected(schedule) {
        if (this.showEditScheduleDropdown) {
            this.showEditScheduleDropdown = false
        } else {
            this.showEditScheduleDropdown = schedule
        }
    }

    editScheduleName() {
        this.modifySchedulePopup = true
        this.editScheduleInnerPopup = true
        // @ts-ignore
        this.editScheduleNameInput = this.showEditScheduleDropdown.name

    }
    deleteSchedule(schedule) {
        this.modifySchedulePopup = true
        this.deleteScheduleComponent = schedule
        this.$dialog.show('confirmModal')
    }

    async created() {
		if (this.$route.params.schedule_id != null) {	
			// @ts-ignore
			scheduleModule.setActiveSchedule({
				id: this.$route.params.schedule_id || ''
			});
		}
    }

    addNewSchedule() {
        // emit
        this.$emit('add-new-schedule');
    }

    selectAddViewOptionBack() {
        // @ts-ignore
        this.selectedAddView = null
    }

    goToSchedulePage(schedule) {
        if (schedule.type == 'add_view') {
            this.addNewSchedule()
            return
        }

        // this.selectedWorkspace = workspace.ID
        this.$router.push({
            name: 'PersonnelScheduler',
            params: {
                // @ts-ignore
                id: scheduleModule.activePersonnelWorkspace.ID,
                schedule_id: schedule.ID
            },
        });
        // @ts-ignore
        scheduleModule.setActiveSchedule({
            id: schedule.ID || ''
        });
    }
    async addNewShiftClicked() {
		this.$emit('did-select-add-shift')
    }

    async onConfirmModalClose({ isOk }) {
        if (isOk) {
            await scheduleModule.deleteShiftSchedule({
                id: this.deleteScheduleComponent.ID
            })
            this.$emit('did-delete-shift-schedule')
        }
    }
}
